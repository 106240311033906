import React, { useState, useEffect, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Grid, Card } from "@material-ui/core";
import EvolutionS from "../../../assets/img/brand/evolutionS.png";
import statusImg from "../../../assets/img/icons/Current_status_MW.png";
import time from "../../../assets/img/icons/alarm.png";
import alarm from "../../../assets/img/icons/alarmRed.png";
import speed from "../../../assets/img/icons/average_speeds_icon.png";
import cycles from "../../../assets/img/icons/cycles.png";
import ancillary_time from "../../../assets/img/icons/ancillery-time-yellow.png";
import idle from "../../../assets/img/icons/idleBlue.png";
import runtime from "../../../assets/img/icons/runtimeGreen.png";
import recipe from "../../../assets/img/icons/recipe_icon.png";
import product from "../../../assets/img/icons/kpi_icon_w.png";
import packs from "../../../assets/img/icons/packs_produced_icon_w.png";
import user from "../../../assets/img/icons/management_panel_icon.png";
import tool from "../../../assets/img/icons/tool.png";
import { color_running } from "components/legen-colors";
import { color_alarm } from "components/legen-colors";
import { color_stopped } from "components/legen-colors";

const useStyles = makeStyles({
  root: {
    display: "flex",
    background: "none",
    borderRadius: "20px",
    padding: "0px",
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    // flex: "1 0 auto",
    padding: "0px",
    paddingBottom: "0px !important",
  },
  cover: {
    width: "100%",
    height: "100%",
    objectFit: "fill",
  },
  playIcon: {
    height: 38,
    width: 38,
  },
});

export default function MachineDataCard({ data }) {
  const classes = useStyles();
  const [machineData, setMachineData] = useState({});

  useEffect(() => {
    let dataProps = data;
    if (dataProps.initialReqKey === "yes") {
      dataProps.AvgSpeed = dataProps.Speed / dataProps.totalDataCount;
      setMachineData({ ...dataProps });
    } else if (
      dataProps.Product !== machineData.Product ||
      dataProps.Recipe !== machineData.Recipe ||
      dataProps.User !== machineData.User
    ) {
      let stateData = { ...machineData };
      stateData.Run_Time_Prod += dataProps.Run_Time_Prod;
      stateData.Alarm_Time += dataProps.Alarm_Time;
      stateData.Idle_Time_Prod += dataProps.Idle_Time_Prod;
      // stateData.Cycles = dataProps.Cycles;
      stateData.ancillaryTime += dataProps.ancillaryTime;
      stateData.batchPacks = dataProps.batchPacks
      stateData.Total_Packs_Prod += dataProps.Total_Packs_Prod
      stateData.AvgSpeed =
        (stateData.Speed + dataProps.Speed) / (stateData.totalDataCount + 1);
      stateData.totalDataCount0 += 1;
      stateData.TIMESTAMP = dataProps.TIMESTAMP;
      setMachineData({ ...stateData });
    } else if (
      dataProps.Tool !== machineData.Tool
    ) {
      let stateData = { ...machineData };
      // if (dataProps.timeStamp !== stateData.timeStamp) {
      stateData.Run_Time_Prod += dataProps.Run_Time_Prod;
      stateData.Alarm_Time += dataProps.Alarm_Time;
      stateData.Idle_Time_Prod += dataProps.Idle_Time_Prod;
      stateData.ancillaryTime += dataProps.ancillaryTime;
      stateData.batchPacks = dataProps.batchPacks;
      stateData.AvgSpeed =
        (stateData.Speed + dataProps.Speed) / (stateData.totalDataCount + 1);
      stateData.totalDataCount += 1;
      // }
      stateData.Total_Packs_Prod += dataProps.Total_Packs_Prod
      stateData.TIMESTAMP = dataProps.TIMESTAMP;
      setMachineData({ ...stateData });
    } else {
      let stateData = { ...machineData };
      // if (dataProps.timeStamp !== stateData.timeStamp) {
      stateData.Run_Time_Prod += dataProps.Run_Time_Prod;
      stateData.Alarm_Time += dataProps.Alarm_Time;
      stateData.Idle_Time_Prod += dataProps.Idle_Time_Prod;
      stateData.ancillaryTime += dataProps.ancillaryTime;
      stateData.batchPacks += dataProps.batchPacks;
      //>> Does average speed needs to be calculated on every transaction or only in Run Status.
      stateData.AvgSpeed =
        (stateData.Speed + dataProps.Speed) / (stateData.totalDataCount + 1);
      stateData.totalDataCount += 1;
      // }
      stateData.Total_Packs_Prod += dataProps.Total_Packs_Prod
      stateData.TIMESTAMP = dataProps.TIMESTAMP;
      setMachineData({ ...stateData });
    }
  }, [data]);

  const getAlarmTimeInHoursAndMinutes = useMemo(() => {
    let seconds = machineData.Alarm_Time;
    if (!seconds || +seconds === 0) return "0h 0m";

    let minutes = seconds / 60;
    let remainingSeconds = seconds % 60;
    if (!minutes || +minutes === 0) return `0h 0m ${remainingSeconds}s`;

    let hours = Math.floor(minutes / 60);
    minutes = minutes % 60;
    minutes = Math.floor(minutes);

    return `${hours}h ${minutes}m ${remainingSeconds}s`;
  }, [machineData.Alarm_Time]);

  const getRunTimeInHoursAndMinutes = useMemo(() => {
    let seconds = machineData.Run_Time_Prod;
    if (!seconds || +seconds === 0) return "0h 0m 0s";

    let minutes = seconds / 60;
    let remainingSeconds = seconds % 60;

    if (!minutes || +minutes === 0) return `0h 0m ${remainingSeconds}`;

    let hours = Math.floor(minutes / 60);
    minutes = minutes % 60;
    minutes = Math.floor(minutes);

    return `${hours}h ${minutes}m ${remainingSeconds}s`;
  }, [machineData.Run_Time_Prod]);

  const getIdleTimeInHoursAndMinutes = useMemo(() => {
    let seconds = machineData.Idle_Time_Prod;
    if (!seconds || +seconds === 0) return "0h 0m";
    let remainingSeconds = seconds % 60;

    let minutes = seconds / 60;

    if (!minutes || +minutes === 0) return `0h 0m ${remainingSeconds}`;

    let hours = Math.floor(minutes / 60);
    minutes = minutes % 60;
    minutes = Math.floor(minutes);

    return `${hours}h ${minutes}m ${remainingSeconds}s`;
  }, [machineData.Idle_Time_Prod]);

  // const getAncillaryTimeInHoursAndMinutes = useMemo(() => {
  //   let seconds = machineData.ancillaryTime;
  //   if (!seconds || +seconds === 0) return "0h 0m";

  //   let minutes = seconds / 60;

  //   if (!minutes || +minutes === 0) return "0h 0m";

  //   let hours = Math.floor(minutes / 60);
  //   minutes = minutes % 60;
  //   minutes = Math.floor(minutes);

  //   return `${hours}h ${minutes}m`;
  // }, [machineData.ancillaryTime]);

  const getAncillaryTimeInHoursMinutesAndSeconds = useMemo(() => {
    let seconds = machineData.ancillaryTime;
    if (!seconds || +seconds === 0) return "0h 0m 0s";

    let minutes = seconds / 60;
    let remainingSeconds = seconds % 60;

    if (!minutes || +minutes === 0) return `0h 0m ${remainingSeconds}`;

    let hours = Math.floor(minutes / 60);
    minutes = minutes % 60;
    minutes = Math.floor(minutes);

    return `${hours}h ${minutes}m ${remainingSeconds}s`;
  }, [machineData.ancillaryTime]);

  // const getRunTimeInHoursAndMinutes = useMemo(() => {
  //   let minutes = machineData.run_time_prod;

  //   console.log("minutes runtime", minutes);
  //   if (!minutes || +minutes === 0) return "0h 0m";

  //   let hours = Math.floor(minutes / 60);
  //   minutes = minutes % 60;

  //   return `${hours}h ${minutes}m`;
  // }, [machineData.Run_Time_Prod]);

  return Object.keys(machineData).length > 0 ? (
    <Card className={classes.root}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={2} className="h-100">
          <div className="container-image-ml h-100">
            <img
              src={machineData.img ? machineData.img : EvolutionS}
              alt="Snow"
              className={classes.cover}
              style={{ opacity: 0.5 }}
            />
            <div className="centered h2 text-white">
              {machineData.machineId}
              {/* {machineData.line} */}
            </div>
          </div>
        </Grid>
        <Grid item xs={10}>
          {machineData.Status === "OFFLINE" ? (
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                item
                xs={12}
                className="w-100 text-white font-weight-bolder h1 py-4"
              >
                {machineData.Status}
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                item
                xs={12}
                className={
                  machineData.Next_Machine === 0
                    ? "bg-warning w-100 py-4"
                    : machineData.Status === "ALARM"
                      ? "bg-danger w-100 py-4"
                      : machineData.Status === "RUN"
                        ? "bg-mw-green w-100 py-4"
                        : machineData.Status === "STOP"
                          ? "bg-info w-100 py-4"
                          : "bg-dark w-100 py-4"
                }
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs={2}>
                    <div
                      className="icon icon-shape text-white rounded-circle shadow"
                      style={{ width: "70px", height: "70px" }}
                    >
                      <img src={time} width={50} alt="" />
                    </div>
                    <Typography
                      className="text-white"
                      component="h5"
                      variant="h5"
                    >
                      Date/Time
                    </Typography>
                    <Typography variant="subtitle1" className="text-white">
                      {machineData.TIMESTAMP}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <div
                      className="icon icon-shape text-white rounded-circle shadow"
                      style={{ width: "70px", height: "70px" }}
                    >
                      <img src={statusImg} width={50} alt="" />
                    </div>
                    <Typography
                      className="text-white"
                      component="h5"
                      variant="h5"
                    >
                      Status
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      className="text-white"
                      style={{ textTransform: "capitalize" }}
                    >
                      {machineData.Status}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <div
                      className="icon icon-shape text-white rounded-circle shadow"
                      style={{ width: "70px", height: "70px" }}
                    >
                      <img src={product} width={50} alt="" />
                    </div>
                    <Typography
                      className="text-white"
                      component="h5"
                      variant="h5"
                    >
                      Product
                    </Typography>
                    <Typography variant="subtitle1" className="text-white">
                      {machineData.Product}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <div
                      className="icon icon-shape text-white rounded-circle shadow"
                      style={{ width: "70px", height: "70px" }}
                    >
                      <img src={recipe} width={50} alt="" />
                    </div>
                    <Typography
                      className="text-white"
                      component="h5"
                      variant="h5"
                    >
                      {/* Recipe */}
                      Batch Packs
                    </Typography>
                    <Typography variant="subtitle1" className="text-white">
                      {/* {machineData.Recipe} */}
                      {machineData.batchPacks}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <div
                      className="icon icon-shape text-white rounded-circle shadow"
                      style={{ width: "70px", height: "70px" }}
                    >
                      <img src={user} width={50} alt="" />
                    </div>
                    <Typography
                      className="text-white"
                      component="h5"
                      variant="h5"
                    >
                      User
                    </Typography>
                    <Typography variant="subtitle1" className="text-white">
                      {machineData.User}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <div
                      className="icon icon-shape text-white rounded-circle shadow"
                      style={{ width: "70px", height: "70px" }}
                    >
                      <img src={packs} width={50} alt="" />
                    </div>
                    <Typography
                      className="text-white"
                      component="h5"
                      variant="h5"
                    >
                      Total Packs
                    </Typography>
                    <Typography variant="subtitle1" className="text-white">
                      {machineData.Total_Packs_Prod}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} className="w-100 py-4">
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs={2}>
                    <div
                      className="icon icon-shape text-white rounded-circle shadow"
                      style={{ width: "70px", height: "70px" }}
                    >
                      <img src={runtime} width={50} alt="" />
                    </div>
                    <Typography
                      // className="text-muted"
                      component="h5"
                      variant="h5"
                      style={{ color: color_running }}
                    >
                      Run Time
                    </Typography>
                    <Typography variant="subtitle1" style={{ color: color_running }}>
                      {getRunTimeInHoursAndMinutes}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <div
                      className="icon icon-shape text-white rounded-circle shadow"
                      style={{ width: "70px", height: "70px" }}
                    >
                      <img src={alarm} width={50} alt="" />
                    </div>
                    <Typography
                      style={{ color: color_alarm }}
                      component="h5"
                      variant="h5"
                    >
                      Alarm Time
                    </Typography>
                    <Typography variant="subtitle1" style={{ color: color_alarm }}>
                      {getAlarmTimeInHoursAndMinutes}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <div
                      className="icon icon-shape text-white rounded-circle shadow"
                      style={{ width: "70px", height: "70px" }}
                    >
                      <img src={idle} width={50} alt="" />
                    </div>
                    <Typography
                      style={{ color: color_stopped }}
                      component="h5"
                      variant="h5"
                    >
                      Idle Time
                    </Typography>
                    <Typography variant="subtitle1" style={{ color: color_stopped }}>
                      {getIdleTimeInHoursAndMinutes}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <div
                      className="icon icon-shape text-white rounded-circle shadow"
                      style={{ width: "70px", height: "70px" }}
                    >
                      <img src={ancillary_time} width={50} alt="" />
                    </div>
                    <Typography
                      component="h5"
                      variant="h5"
                      style={{ color: "yellow" }}
                    >
                      Ancillary Time
                    </Typography>
                    <Typography variant="subtitle1" style={{ color: "yellow" }}>
                      {/* {machineData.ancillaryTime} */}
                      {getAncillaryTimeInHoursMinutesAndSeconds}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <div
                      className="icon icon-shape text-white rounded-circle shadow"
                      style={{ width: "70px", height: "70px" }}
                    >
                      <img src={speed} width={50} alt="" />
                    </div>
                    <Typography
                      className="text-muted"
                      component="h5"
                      variant="h5"
                    >
                      Speed
                    </Typography>
                    <Typography variant="subtitle1" className="text-muted">
                      {console.log('machineData', machineData)}
                      {console.log('data', data)}
                      {console.log('machineData.Speed', machineData.Speed)}
                      {data.RecentSpeed.toFixed(0)} ppm
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <div
                      className="icon icon-shape text-white rounded-circle shadow"
                      style={{ width: "70px", height: "70px" }}
                    >
                      <img src={tool} width={50} alt="" />
                    </div>
                    <Typography
                      className="text-muted"
                      component="h5"
                      variant="h5"
                    >
                      Tool
                    </Typography>
                    <Typography variant="subtitle1" className="text-muted">
                      {machineData.Tool}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {/* </>
            )} */}
            </Grid>
          )}
        </Grid>
      </Grid>
    </Card>
  ) : (
    "No Data Found"
  );
}
