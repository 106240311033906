import React, { useState, useEffect } from "react";
import { Col, Card, Spinner, Button } from "reactstrap";
import Table from "views/shared/Table";
import { Grid, TablePagination } from "@material-ui/core";
import axios from "axios";
import { baseUrl } from "../../constants/url";
import authHeader from "../../services/auth-header";
import AddNewModal from "views/shared/AddNewModal";
import ConfirmationModal from "views/shared/ConfirmationModal";
import AuthService from "services/auth.service";
import { toast } from "react-toastify";
// import UserRoleEnums from "utils/roles-enums";

function UsersTable({
  filtersData,
  fetchData,
  tableData,
  setTableData,
  count,
}) {
  const user = AuthService.getCurrentUser();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [openModal, setOpenModal] = useState(false);
  const [infoModalData, setInfoModalData] = useState({
    key: null,
    title: null,
    modalData: null,
    modalButtonText: null,
  });
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [confirmationModalData, setConfirmationModalData] = useState({
    title: "",
    body: "",
    confirmText: "",
  });
  const [deleteUserData, setDeleteUserData] = useState({});
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    setTableData("Loading...");
    fetchData();
  }, [filtersData, page, rowsPerPage]);

  const tableColumns = [
    {
      id: "username",
      title: "Username",
      className: "sort custom-padding",
      onClick: null,
      render: null,
    },
    {
      id: "firstName",
      title: "First Name",
      className: "sort custom-padding",
      onClick: null,
      render: null,
    },
    {
      id: "lastName",
      title: "Last Name",
      className: "sort custom-padding",
      onClick: null,
      render: null,
    },
    {
      id: "email",
      title: "Email",
      className: "sort custom-padding",
      onClick: null,
      render: null,
    },
    {
      id: "clientName",
      title: "Client",
      className: "sort custom-padding",
      onClick: null,
      render: null,
    },
    {
      id: "distributorName",
      title: "Distributor",
      className: "sort custom-padding",
      onClick: null,
      render: null,
    },
    {
      id: "role",
      title: "Role",
      className: "sort custom-padding",
      onClick: null,
      render: null,
    },
    {
      id: "contactNumber",
      title: "Number",
      className: "sort custom-padding",
      onClick: null,
      render: null,
    },
    {
      id: "action",
      title: "ACTION",
      className: "sort text-right custom-padding",
      onClick: null,
      render: (record) => renderHtml("action", record),
    },
  ];

  const renderHtml = (key, record) => {
    switch (key) {
      case "action":
        if (record.username === user.username || (user.role !== 'admin' && record.role === 'admin')) {
          return;
        } else {
          return (
            <>
              <i
                className="text-info btn fa fa-edit px-1 py-0 m-0"
                onClick={() => {
                  setOpenModal(true);
                  setInfoModalData({
                    key: "user",
                    title: "Update User",
                    modalData: {
                      ...record,
                      // role: UserRoleEnums[record.role],
                    },
                    modalButtonText: "Update",
                  });
                }}
              />
              <i
                className="text-danger btn fa fa-trash px-1 py-0 m-0"
                onClick={() => {
                  setConfirmationModalData({
                    title: `Delete user ${record.username}`,
                    body: "Are you sure you want to delete this user?",
                    confirmText: "Confirm",
                  });
                  setDeleteUserData(record);
                  setOpenConfirmationModal(true);
                }}
              />
            </>
          )
        }

      default:
        break;
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDelete = () => {
    setIsDeleting(true);
    axios
      .delete(`${baseUrl}/api/admin/user/${deleteUserData.id}`, {
        headers: authHeader(),
      })
      .then((res) => {
        fetchData();
        setIsDeleting(false);
        setOpenConfirmationModal((prevState) => !prevState)
        toast.success("User deleted successfully");
      })
      .catch((err) => {
        console.log(err);
        setIsDeleting(false);
        toast.error("Error deleting user");
      });
  };

  return (
    <>
      <Col className="h-100">
        <Card className="shadow h-100">
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <h3 className="px-4 pt-4" style={{ color: "#0ca6f2" }}>
              Users
            </h3>
            <div className="mr-4 pt-3">
              <Button
                style={{ backgroundColor: "#0ca6f2", border: "none" }}
                className="px-4"
                size="sm"
                onClick={() => {
                  setOpenModal(true);
                  setInfoModalData({
                    key: "user",
                    title: "Add User",
                    modalData: null,
                    modalButtonText: "Add",
                  });
                }}
              >
                Add
                <i className="fa fa-plus ml-2" />
              </Button>
            </div>
          </Grid>
          {tableData === "Loading..." ? (
            <div className="row justify-content-center my-5">
              <Spinner>Loading...</Spinner>
            </div>
          ) : tableData ? (
            <>
              <Table
                values={tableData.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )}
                columns={tableColumns}
                tableClasses={{
                  table:
                    "table align-items-center table-flush table-sm border-bottom text-white table-custom",
                  thead: "",
                  tbody: "list text-muted",
                }}
              />
              <TablePagination
                component="div"
                className="text-muted"
                count={count}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />{" "}
            </>
          ) : (
            "Not Available"
          )}
        </Card>
      </Col>
      {openModal && (
        <AddNewModal
          isOpen={openModal}
          toggle={() => setOpenModal((prevState) => !prevState)}
          modalKey={infoModalData.key}
          modalTitle={infoModalData.title}
          modalData={infoModalData.modalData}
          modalButtonText={infoModalData.modalButtonText}
          fetchData={fetchData}
          user={user}
        />
      )}
      <ConfirmationModal
        isOpen={openConfirmationModal}
        toggle={() => setOpenConfirmationModal((prevState) => !prevState)}
        title={confirmationModalData.title}
        body={confirmationModalData.body}
        confirmText={confirmationModalData.confirmText}
        modalData={deleteUserData}
        handleDelete={handleDelete}
        isDeleting={isDeleting}
      />
    </>
  );
}

export default UsersTable;
