import React, { useState } from "react";
import Filters from "views/shared/Filters";
import { Container, Card, CardBody } from "reactstrap";
import ChartContainer from "./ChartsContainer.jsx";
import { useParams } from "react-router-dom";

function ProductionData() {
  const params = useParams();
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  const [filtersData, setFiltersData] = useState({
    // start_date: "2023-01-13T11:35:04.00Z",
    start_date: yesterday.toISOString(),
    // end_date: "2023-01-13T11:35:04.30Z",
    end_date: today.toISOString(),
    recipe: "All",
    product: "All",
  });

  const handleApplyFilters = (filterData) => {
    setFiltersData({ ...filterData });
  };

  return (
    <Container fluid>
      <Card className="m-3 mb-4">
        <CardBody className="rounded px-5 py-4">
          <Filters
            filtersData={filtersData}
            handleApplyFilters={handleApplyFilters}
            clientId={params.clientId}
            machineId={params.machineId}
          />
        </CardBody>
      </Card>
      <ChartContainer
        filtersData={filtersData}
        setFiltersData={setFiltersData}
        clientId={params.clientId}
        machineId={params.machineId}
      />
    </Container>
  );
}

export default ProductionData;
